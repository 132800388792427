<template>
	<div class="error error--404">
		<div class="error__container">
			<div class="error__main">
				<div class="error__title">404</div>
				<div class="error__subtitle">Кажется такой<br>страницы у нас нет</div>
			</div>

			<div class="error__info">
				Вы зашли на несуществующую страницу, вернитесь на <router-link :to="{ name: 'Root' }">главную страницу</router-link>
			</div>
		</div>

		<footer class="error__footer">
			<span class="copyright">
				<img src="/img/copyright.svg" alt="copyright"> Beresnev Games, 2017-{{currentYear}}
			</span>

			<img src="/img/logo-small.svg" alt="beresnev logo">
		</footer>
	</div>
</template>

<script>
export default {
	name: "404-view",
	computed: {
		currentYear(){
			return (new Date()).getFullYear();
		}
	}
}
</script>

<style scoped lang="sass">

.error
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	position: relative

	&__container
		position: relative
		display: flex
		flex-direction: column
		align-items: flex-start
		grid-gap: 5px
		top: 60px
		width: 100%
		padding: 0 20px

	&__wrapper

	&__main

	&__title
		font-size: 140px
		line-height: .8

	&__info
		width: 100%
		font-size: 17px

	&__subtitle
		font-weight: 600
		font-size: 23px
		margin-bottom: 31px

	&__footer
		position: absolute
		bottom: 0
		left: 0
		right: 0
		display: flex
		flex-direction: column
		align-items: center
		grid-gap: 22px
		padding: 22px


.copyright
	font-size: 13px
	color: $gray-dark-3

@media screen and (min-width: 581px)
	.error
		&__container
			width: 430px
			padding: 0

			&::after
				content: ''
				background: url('/img/404.png') no-repeat
				background-size: contain
				width: 609px
				height: 465px
				max-width: 100vw
				position: absolute
				z-index: -1
				left: -76px
				top: -205px

		&__title
			font-size: 222px

		&__subtitle
			font-size: 36px

		&__info
			width: 333px
</style>